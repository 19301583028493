<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const props = defineProps<{
  class?: HTMLAttributes["class"];
}>();
</script>

<template>
  <div
    :class="cn('animate-pulse rounded-md bg-on-surface/[.15]', props.class)"
  />
</template>
